import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import P from '../components/_common/P';
import H3 from '../components/_common/H3';
import H1 from '../components/_common/H1';
import PageLayout from '../layouts/PageLayout';
import ContentSection from '../components/ContentSection';
import SubpageTopCover from '../components/SubpageTopCover';
import { useContactPicture } from '../hooks/use-contact-picture';
import { colors } from '../globalStyles';

const StyledPrivacyPolicy = styled.div`
	${P} {
		line-height: 2;
	}
	${H3}, ${H1} {
		text-align: center;
	}
	${H3} {
		margin: 40px 0 20px;
	}
	a {
		color: ${colors.orange};
	}
`;
const CookiesPolicy = ({ location }) => {
	const img = useContactPicture();
	return (
		<PageLayout seoConfig={{}} location={location}>
			<SubpageTopCover backgroundImg={img}>Cookies Policy</SubpageTopCover>
			<StyledPrivacyPolicy>
				<ContentSection noHeader>
					<P style={{ textAlign: 'right', margin: '40px 0' }}>
						Last updated: March 28, 2019
					</P>
					<P>
						PixelTeh (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) uses
						cookies on the https://pixelteh.com website (the
						&quot;Website&quot;). By using the Website, you consent to the use
						of cookies.
					</P>

					<P>
						Our Cookies Policy explains what cookies are, how we use cookies,
						how third-parties we may partner with may use cookies on the
						Website, your choices regarding cookies and further information
						about cookies.
					</P>

					<H3>What are cookies</H3>

					<P>
						Cookies are small pieces of text sent by your web browser by a
						website you visit. A cookie file is stored in your web browser and
						allows the Website or a third-party to recognize you and make your
						next visit easier and the Website more useful to you.
					</P>

					<P>
						Cookies can be &quot;persistent&quot; or &quot;session&quot;
						cookies. Persistent cookies remain on your personal computer or
						mobile device when you go offline, while session cookies are deleted
						as soon as you close your web browser.
					</P>

					<H3>How PixelTeh uses cookies</H3>

					<P>
						We may use third-party Website Providers to monitor and analyze the
						use of our Website.
					</P>
					<ul>
						<li>
							<P>
								<strong>Google Analytics</strong>
							</P>
							<P>
								Google Analytics is a web analytics service offered by Google
								that tracks and reports website traffic. Google uses the data
								collected to track and monitor the use of our Website. This data
								is shared with other Google services. Google may use the
								collected data to contextualize and personalize the ads of its
								own advertising network.
							</P>
							<P>
								You can opt-out of having made your activity on the Website
								available to Google Analytics by installing the Google Analytics
								opt-out browser add-on. The add-on prevents the Google Analytics
								JavaScript (ga.js, analytics.js, and dc.js) from sharing
								information with Google Analytics about visits activity.
							</P>{' '}
							<P>
								For more information on the privacy practices of Google, please
								visit the Google Privacy &amp; Terms web page:{' '}
								<a href="https://policies.google.com/privacy?hl=en">
									https://policies.google.com/privacy?hl=en
								</a>
							</P>
						</li>
					</ul>

					<H3>What are your choices regarding cookies</H3>

					<P>
						If you&apos;d like to delete cookies or instruct your web browser to
						delete or refuse cookies, please visit the help pages of your web
						browser. As an European citizen, under GDPR, you have certain
						individual rights. You can learn more about these rights in the{' '}
						<a href="https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR">
							GDPR Guide
						</a>
						.
					</P>

					<P>
						Please note, however, that if you delete cookies or refuse to accept
						them, you might not be able to use all of the features we offer, you
						may not be able to store your preferences, and some of our pages
						might not display properly.
					</P>

					<ul>
						<li>
							<P>
								For the Chrome web browser, please visit this page from Google:{' '}
								<a href="https://support.google.com/accounts/answer/32050">
									https://support.google.com/accounts/answer/32050
								</a>
							</P>
						</li>
						<li>
							<P>
								For the Internet Explorer web browser, please visit this page
								from Microsoft:{' '}
								<a href="http://support.microsoft.com/kb/278835">
									http://support.microsoft.com/kb/278835
								</a>
							</P>
						</li>
						<li>
							<P>
								For the Firefox web browser, please visit this page from
								Mozilla:{' '}
								<a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
									https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
								</a>
							</P>
						</li>
						<li>
							<P>
								For the Safari web browser, please visit this page from Apple:{' '}
								<a href="https://support.apple.com/kb/PH31411?locale=en_US">
									https://support.apple.com/kb/PH31411?locale=en_US
								</a>
							</P>
						</li>
						<li>
							<P>
								For any other web browser, please visit your web browser&apos;s
								official web pages.
							</P>
						</li>
					</ul>

					<H3>Where can you find more information about cookies</H3>

					<P>
						You can learn more about cookies and the following third-party
						websites:
					</P>

					<ul>
						<li>
							<P>
								AllAboutCookies:{' '}
								<a href="http://www.allaboutcookies.org/">
									http://www.allaboutcookies.org/
								</a>
							</P>
						</li>
						<li>
							<P>
								Network Advertising Initiative:{' '}
								<a href="http://www.networkadvertising.org/">
									http://www.networkadvertising.org/
								</a>
							</P>
						</li>
					</ul>
				</ContentSection>
			</StyledPrivacyPolicy>
		</PageLayout>
	);
};

CookiesPolicy.propTypes = {
	location: PropTypes.object,
};

export default CookiesPolicy;
