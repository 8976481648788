import { graphql, useStaticQuery } from 'gatsby';

export const useContactPicture = () => {
	// const image = data.file.childImageSharp.fluid
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "contact-page-bg.jpg" }) {
				...ChildImageSharpFluid
			}
		}
	`);
	return data.file.childImageSharp.fluid;
};
